import { Link } from 'react-router-dom';
import logo from './static/ico-logo-white.png'

let Footer = (props) => {
    let donateLink = props.pages.find(link => { return link.title === "Donate" })
    return (
        <footer className="footer">
            <div className="footer__logo">
                <Link to="/"><img src={logo} alt='ICO'></img></Link>
            </div>
            <div className="footer__content-container">
                <div className="footer-content footer__address">{props.companyInfo.address.street}</div>
                <div className="footer-content footer__address">{props.companyInfo.address.city} {props.companyInfo.address.state} {props.companyInfo.address.zip}</div>
                <div className="footer-content footer__social-media">
                    <Link to="/Contact">
                        <button className="social-media__button">
                            <img src="./static/media/email.png" alt="email"></img>
                        </button>
                    </Link>
                    <a href="tel:706-224-3252"><button className="social-media__button">
                        <img src="./static/media/phone-call.png" alt="phone number"></img>
                    </button></a>
                    {/* <a href=''><button className="social-media__button">
                       <img src="./static/media/facebook.png" alt="facebook page"></img>
                    </button></a> */}
                </div>
                <div className="footer-content footer__links">
                    <a href={donateLink.href} target="_blank"><button className="links__button">{donateLink.title}</button></a>
                </div>
                <div className="footer-content footer__info">{props.companyInfo.infoText}</div>
            </div>
        </footer>
    )
}

export default Footer