import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './Header.js'
import Postwall from './Postwall.js'
import Contact from './Contact.js'
import ContactSuccess from './ContactSuccess.js'
import CarouselContainer from './Carousel.js'
import Footer from './Footer.js'
import Construction from './Construction.js'
import About from './About.js'

class Main extends Component {
    constructor() {
        super()
    }

    render() {
        return (
            <Routes>
                <Route path='/' element={
                    <div>
                        <Header {...this.props} />
                        <CarouselContainer {...this.props} />
                        <Postwall {...this.props} />
                        <Footer {...this.props} />
                    </div>
                } />
                <Route path='/Contact' element={
                    <div>
                        <Header {...this.props} />
                        <Contact />
                    </div>
                } />
                <Route path='/ContactSuccess' element={
                    <div>
                        <Header {...this.props} />
                        <ContactSuccess />
                    </div>
                } />
                <Route path='/About' element={
                    <div>
                        <Header {...this.props} />
                        <About {...this.props} />
                        <Footer {...this.props} />
                    </div>
                } />
                <Route path='/Events' element={
                    <div>
                        <Header {...this.props} />
                        <Construction />
                    </div>
                } />
                <Route path='/Resources' element={
                    <div>
                        <Header {...this.props} />
                        <Construction />
                    </div>
                } />
            </Routes>
        )
    }
}

export default Main
